
$(document).ready(function() {

	$(document).on('click', '.change-location-trigger', function() {
		$.magnificPopup.close();
		$('body').addClass('mfp-open');
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'change-location-popup fade-in',
			tLoading: coreData.loader,
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "changeLocationPopup",
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			},
			callbacks: {
				ajaxContentAdded: function() {

					$(".location-select-trigger").on("click", function() {
						$('.section-active').removeClass('active');
						$(this).children('.section-active').addClass('active');
						locationSelect($(this).data('location-id'));
						$.magnificPopup.close();
					});

				}
			}
		});
		$.magnificPopup.instance.close = function () {
			$('body').removeClass('mfp-open');
			$.magnificPopup.proto.close.call(this);
		}
	});

});

$(window).on('load', function() {

	window.locationSelect = function(locationID, callback) {
		Cookies.set('location-selected', locationID);
		$(window).trigger("updateLocation");
		if (typeof callback !== 'undefined') {
			callback(locationID);
		}
	};
});


$(window).on('updateLocation', function() {

	$(".desktop-top-bar .section-selection .nav-link").addClass('active');

	$(".desktop-top-bar .phone-loader").fadeTo('fast', 1, function() {
		$(".desktop-top-bar .section-phone-wrapper").addClass('loading');
		$(".desktop-top-bar .section-phone-wrapper").load(coreData.ajaxurl, {
			action: 'topBarPhone'
		}, function() {
			$(".desktop-top-bar .section-phone-wrapper").removeClass('loading');
			$(".desktop-top-bar  .phone-loader").fadeTo('fast',0);
		});
	});

	$(".desktop-top-bar .address-loader").fadeTo('fast', 1, function() {
		$(".desktop-top-bar .section-address-wrapper").addClass('loading');
		$(".desktop-top-bar .section-address-wrapper").load(coreData.ajaxurl, {
			action: 'topBarAddress'
		}, function() {
			$(".desktop-top-bar .section-address-wrapper").removeClass('loading');
			$(".desktop-top-bar  .address-loader").fadeTo('fast',0);
		});
	});

	$(".desktop-top-bar .hours-loader").fadeTo('fast', 1, function() {
		$(".desktop-top-bar .section-hours-wrapper").addClass('loading');
		$(".desktop-top-bar .section-hours-wrapper").load(coreData.ajaxurl, {
			action: 'topBarHours'
		}, function() {
			$(".desktop-top-bar .section-hours-wrapper").removeClass('loading');
			$(".desktop-top-bar  .hours-loader").fadeTo('fast',0);
		});
	});

	$(".desktop-top-bar .button-loader").fadeTo('fast', 1, function() {
		$(".desktop-top-bar .section-button-wrapper").addClass('loading');
		$(".desktop-top-bar .section-button-wrapper").load(coreData.ajaxurl, {
			action: 'topBarButton'
		}, function() {
			$(".desktop-top-bar .section-button-wrapper").removeClass('loading');
			$(".desktop-top-bar  .button-loader").fadeTo('fast',0);
		});
	});

});
