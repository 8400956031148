/* Treatment Carousel Block JS File */
import slick from 'slick-carousel';

$(document).ready(function() {

	if ($(".treatment-carousel-wrapper .slick-treatment-image")) {

		const max = parseInt($('.treatment-carousel-navigation').attr('data-count'), 10);

		$(".treatment-carousel-wrapper .slick-treatment-image").on('init', function() {
			// $('.slick-slide.slick-active', $(this)).addClass('slick-fix-active');
			// $('.slick-slide.slick-fix-active').each(function(index, element) {
			// 	if (index == 0) {
			// 		$(element).addClass('slick-fix-active-start');
			// 	}
			// 	else if ((index + 1) == $('.slick-slide.slick-fix-active').length) {
			// 		$(element).addClass('slick-fix-active-end');
			// 	}

			// });
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 5000,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: true,
			pauseOnFocus: true,
			pauseOnHover: true,
			arrows: true,
			appendArrows: '.treatment-carousel-navigation',
			prevArrow: '<span><i class="far fa-arrow-left"></i></span><div class="pagination"> <span id="current">1</span> / <span id="max">'+ max +'</span>',
			nextArrow: '</div><span><i class="far fa-arrow-right"></i></span>',
			dots: false,
			centerMode: true,
			centerPadding: '0',
			focusOnSelect: true,
			rows: 0,
			fade: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						fade: false,
						centerMode: true
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						fade: false,
						centerMode: true
					}
				},
			]
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {

			if ((nextSlide + 1) > max) {
				$('#current').text((nextSlide + 1) - max);
			} else {
				$('#current').text(nextSlide + 1);
			}
			// $('.slick-slide', $(this)).removeClass('slick-fix-active-start');
			// $('.slick-slide', $(this)).removeClass('slick-fix-active-end');
			// $('.slick-slide', $(this)).removeClass('slick-fix-active');

			// $('.slick-slide[data-slick-index="' + (nextSlide - 1) + '"]').addClass('slick-fix-active-start');
			// $('.slick-slide[data-slick-index="' + (nextSlide - 1) + '"]').addClass('slick-fix-active');
			// $('.slick-slide[data-slick-index="' + (nextSlide + 0) + '"]').addClass('slick-fix-active');
			// $('.slick-slide[data-slick-index="' + (nextSlide + 1) + '"]').addClass('slick-fix-active');
			// $('.slick-slide[data-slick-index="' + (nextSlide + 2) + '"]').addClass('slick-fix-active');
			// $('.slick-slide[data-slick-index="' + (nextSlide + 2) + '"]').addClass('slick-fix-active-end');

			// if ((currentSlide == 0) && nextSlide == max) {
			// 	$('.slick-slide[data-slick-index="' + -1 + '"]').addClass('slick-fix-current');
			// }
			// else if ((currentSlide == max || currentSlide == (max - 1) ||
			// 	currentSlide == (max - 2)) && nextSlide == 0) {
			// 	$('.slick-slide[data-slick-index="' + (max + 1) + '"]').addClass('slick-fix-current');
			// }
			// else if ((currentSlide == max || currentSlide == (max - 1)) && nextSlide == 1) {
			// 	$('.slick-slide[data-slick-index="' + (max + 2) + '"]').addClass('slick-fix-current');
			// }
			// else if ((currentSlide == max) && nextSlide == 2) {
			// 	$('.slick-slide[data-slick-index="' + (max + 3) + '"]').addClass('slick-fix-current');
			// }

		}).on('afterChange', function(event, slick, currentSlide, nextSlide) {
			// $('.slick-slide').removeClass('slick-fix-current');
		});
	}

});
