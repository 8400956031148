/* Banner Home Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-home-wrapper").length) {

		$(".banner-home-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			appendDots: '.banner-slide-navigation',
			fade: true,
			rows: 0,
			adaptiveHeight: false,
			customPaging: function(slider, i) {
				return '<button><span>' + ('0' + (i + 1)).slice(-2) + '</span></button>';
			}
		});

		const bannerHomeController = new ScrollMagic.Controller();
		const bannerHomeScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".banner-home-wrapper .section-banner-background .background", {
			y: "40%"
		});
		bannerHomeScene.addTo(bannerHomeController);
	}
});

