/* Photo Gallery Block JS File */
$(document).ready(function() {
	if ($(".photo-gallery-wrapper").length) {
		$('.section-body').magnificPopup({
			delegate: 'a',
			type: 'image',
			closeOnContentClick: false,
			closeBtnInside: false,
			mainClass: 'mfp-with-zoom mfp-img-mobile',
			image: {
				verticalFit: true,
			},
			gallery: {
				enabled: true
			},
			zoom: {
				enabled: true,
				duration: 500, // don't foget to change the duration also in CSS
				opener: function(element) {
					return element.find('div.background');
				}
			}
		});
	}
});
